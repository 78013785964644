/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios'
import { Toast, Dialog } from 'vant-green'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 3600000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers.RESPONSE_RESULT_ANN = 'RESPONSE_RESULT_ANN'
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (!Object.keys(res).includes('code') || res.code === 70003 || res.code === 70004 || res.code === 70006) {
      // to re-login
    } else {
      // if the custom code is not 20000, it is judged as an error.
      if (res.code !== 0) {
        Dialog.alert({
          title: '请求出错',
          message: (res.message || '请求出现错误，请联系管理员').concat('<br/>技术支持：刘老师<br/>电话：17791243312'),
          confirmButtonText: '我知道了'
        }).then(() => {
          // on close
          // 统一提示错误
          return Promise.reject(new Error(res.message || 'Error'))
        });
        // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;


      }
      return { code: res.code, message: res.message, data: res.data }
    }
  },
  error => {
    console.log('err' + error) // for debug
    if (error.message === 'timeout of 5000ms exceeded') {
      error.message = '服务器连接超时，请检查网络，错误码90001'
    } else if (error.message === 'Network Error') {
      error.message = '服务器连接错误，请检查网络，错误码90002'
    } else if (error.message === 'Request failed with status code 500') {
      error.message = '服务器发生内部错误,请检查网络，错误码90003'
    } else if (error.message === 'Request failed with status code 500') {
      error.message = '您请求的服务可能暂未开放，提示码500，请联系管理员'
    }
    Toast.fail(error.message || 'Error')
    return Promise.reject(error)
  }
)

export default service
