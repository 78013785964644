import request from '@/utils/request'

/**发送短信验证码 */
export function fetchSmsCode(params) {
    return request({
        url: '/meeting/send/sms',
        method: 'post',
        params
    })
}

/**确认报名信息 */
export function fetchJoinMeeting(data) {
    return request({
        url: '/meeting/check/in',
        method: 'post',
        data
    })
}

/**根据姓名查询签到信息 */
export function fetchListByName(params) {
    return request({
        url: '/meeting/list/name',
        method: 'get',
        params
    })
}

/**确认签到信息 */
export function fetchSignMeeting(params) {
    return request({
        url: '/meeting/sign/up',
        method: 'post',
        params
    })
}